<template>
  <v-container fluid>

    <v-row>        
      <v-col
        cols="12"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            
            <v-icon left dense> mdi-cogs </v-icon>
            Layouts
            <v-spacer/>

          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="selectedLayouts"
              sort-by="LayoutId"
              single-select
              return-object
              class="elevation-5"
            >
            
            <template v-slot:item.Stalls="{ item }">
                <span v-for="(stall, index) in item.Stalls" :key="index">
                  - {{stall.StallName}} <br/>
                </span>
            </template>
            
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          
            
            <!-- Stallholder menu -->
            <template v-slot:top>
              <v-toolbar
                flat
              >
              <v-row>
                  <v-col cols="4">
                    <v-autocomplete
                        :items="allStallHolders"
                        v-model="selectStallHolder"
                        item-text="StallholderName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="Stallholders"
                        @change=handleChangeStallholder
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                                mdi-information
                            </v-icon>
                              No event found
                            </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                  </v-col>
                </v-row>

              <v-row class="mt-2 mb-2">
                <v-col cols="12"></v-col>
              </v-row>
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                  dark
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      dark
                      class="mb-2"
                      @click="addLayout()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left x-small>
                        mdi-plus
                      </v-icon>
                      Add Layout
                    </v-btn>

                  </template>

                </v-dialog>

                <v-dialog v-model="dialogDeleteItem" max-width="520px">
                  <v-card>
                    <!-- #EBC09A -->
                    <v-card-title class="text-h6 text-wrap justify-center">Are you sure you want to delete this layout?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="red darken-1" text @click="deleteItemConfirmed">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-toolbar>
            </template>
            
            <template v-slot:no-data>
              <v-btn
                color="primary"
              >
                No data available
              </v-btn>
            </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast/dist/js/iziToast.min.js';
import {
  mapState,
  mapActions,
mapMutations
} from 'vuex'

export default {
  data: () => ({
    dialog: false,
    dialogDeleteItem: false,
    dialogDeleteAllItems: false,

    headers: [
      { text: "Layout Name", value: "LayoutName", width: '30%'},
      { text: "Stallholder Name", value: "StallholderName", align: 'left', width: '30%'},
      { text: "Stalls", value: "Stalls", align: 'left', width: '30%'},
      { text: 'Actions', value: 'actions', align: 'right', width: '10%', sortable: false}
    ],

    selectedLayouts: [],
    layouts: [],

    deleteItemId: '',

    editedIndex: -1,
    editedItem: {
      StallholderId: '',
      LayoutId: ''
    },
    defaultItem: {
      StallholderId: '',
      StallId: '',
      TerminalUserId: '',
      TerminalId: '',
      LayoutId: ''
    },
    selectedStallholder: '',

    valid: true,

  }),

  computed: {
    ...mapState('filter', ['globalEventFilter', 'allStallHolders', 'filterStallHolders']), // delete filterStallHolders ???
    ...mapState("admin", [
      "processedLayout"
    ]),
    
    formTitle () {
      return this.editedIndex === -1 ? 'New Layout' : 'Edit Layout'
    },

      selectStallHolder: {
        get() {
          return this.allStallHolders[0]
        },
        set (value) {
          this.selectedStallholder = value
        }
      },
  },

  created() {
    this.getAllLayouts()
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDeleteItem (val) {
      val || this.closeDelete()
    },

    // selectStallholder: function () {
    //   // this.selectStallholder = this.selectedStallholder
      
    // },

    globalEventFilter: function(val) {      
      let eventId = val.EventId
      let paramObj = {}
          paramObj.EventId = eventId;

      this.getAllLayouts(paramObj).then(response => {
        this.terminalLayouts = response;
      })
    },

  },

  methods: {
    ...mapActions('admin', ['getLayouts', 'postLayouts']),
    ...mapMutations("admin", [
      "setLayout"
    ]),
        
    getAllLayouts() {

      if (this.selectedStallholder === '') {
        this.handleChangeStallholder(this.globalEventFilter.Stallholders[0])
      }

      let paramObj = {}
          paramObj.eventId = this.globalEventFilter.EventId;
          paramObj.stallholderId = this.selectedStallholder.StallholderId;
      
          console.log('this.getAllLayouts paramObj: ',paramObj)

      this.getLayouts(paramObj).then(response => {
        console.log('this.getAllLayouts response: ',response)
        this.selectedLayouts = response.Layouts;
      });

    },

    handleChangeStallholder: function (obj) {
      localStorage.setItem('setStallholderObj', JSON.stringify(obj))
      
      this.selectedStallholder = obj
      this.getAllLayouts()
    },

    addLayout: function () {
      this.setLayout({})
      localStorage.setItem('setEventObj', JSON.stringify(this.globalEventFilter.EventId))
      localStorage.setItem('setStallholderObj', JSON.stringify(this.selectedStallholder))
      localStorage.setItem('setLayoutObj', JSON.stringify({}))
      this.$router.push({
        name: 'Layout Configuration',
        params: { id: 'create' }
      })
    },

    editItem (item) {
      console.log('editItem item: ',item)
      
      localStorage.setItem('setEventObj', JSON.stringify(this.globalEventFilter.EventId))
      localStorage.setItem('setStallholderObj', JSON.stringify(this.selectedStallholder))
      localStorage.setItem('setLayoutObj', JSON.stringify(item))
      
      this.$router.push({
        name: 'Layout Configuration',
        params: { id: 'update' }
      })
    },

    deleteItem (item) {
      console.log('deleteItem (item)',item)
      this.deleteItemId = item.LayoutId
      this.dialogDeleteItem = true
    },

    deleteItemConfirmed (item) {
      console.log('deleteItemConfirmed (item)',item)
      console.log('deleteItemConfirmed (item)',this.deleteItemId)
      
      try {
        if (this.deleteItemId) {
            let deletedItem =
                {
                    Type: "Layout",
                    Action: "delete",
                    EventId: JSON.stringify(this.globalEventFilter.EventId),
                    LayoutId: this.deleteItemId
                }

              console.log('DELETE: ',deletedItem)
            
            this.postLayouts(deletedItem).then(response => {
                console.log('deleteItemConfirmed: ',response)

                iziToast.success({
                    title: 'Layout',
                    message: 'Successfully deleted the Layout!',
                    position: 'topRight'
                });

                this.getAllLayouts();

            }).catch(err => {
                console.log("ERROR: ",err)

                iziToast.warning({
                    title: 'Layout',
                    message: 'Failed to delete record!',
                    position: 'center'
                });
            })
        }
      } catch (e) {
        console.log(e)
      }

      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDeleteItem = false
      this.deleteItemId = ''
    },
  }
}
</script>


<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
.v-data-table-header th {
  white-space: nowrap;
}
</style>