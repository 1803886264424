<template>
    <v-container fluid>
  
      <v-row>        
        <v-col
          cols="12"
        >
          <v-card
            dark
            outlined
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-home </v-icon>
               Product Options
              <v-spacer/>
              <!-- <v-btn
                x-small
                dark
                @click="haddleClick()"
              >
                <v-icon left x-small>
                  mdi-plus
                </v-icon>
                Add User
              </v-btn> -->
            </v-card-title>
            <v-card-text class="pa-0">

              <v-data-table
                :headers="headers"
                :items="productOptions"
                single-select
                class="elevation-5"
              >
              <template v-slot:item.ProductOptionName="{ item }">
                  {{ item.ProductOptionName ? item.ProductOptionName : 'No data available' }}
              </template>
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                <v-row class="mt-2 mb-2">
                    <v-col cols="2">
                    <v-autocomplete
                        :items="allStallHolders"
                        v-model="selectedStallHolder"
                        item-text="StallholderName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="StallHolders"
                        @change="haddleChangeSH"
                    >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                                mdi-information
                            </v-icon>
                            No event found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                    </v-col>
                </v-row>
                  <v-dialog
                    v-model="dialog"
                    max-width="800px"
                    dark
                  >
                  
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!selectedStallHolder.StallholderId"
                      >
                        <v-icon left x-small>
                          mdi-plus
                        </v-icon>
                        Add Product Option
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ formTitle }}
                      </v-card-title>
                      <v-divider></v-divider>
  
                      <v-card-text>
                        <v-container>
                         <v-form 
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >

                            <v-row>
                                <v-col cols="6">
                                    <label><B>Event :-</B> {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}</label>
                                </v-col>
                                <v-col cols="6">
                                    <label><B>Stallholder :-</B> {{ selectedStallHolder.StallholderName ? selectedStallHolder.StallholderName : 'N/A'}}</label>
                                </v-col>
                            </v-row>

                            <v-text-field
                            v-model="editedItem.ProductOptionName"
                            label="Product Option Name"
                            :rules="[v => !!v || 'Product Option Name is required']"
                            ></v-text-field>

                            <v-text-field
                            v-model="editedItem.Price"
                            label="Price"
                            type="number"
                            :rules="[v => !!v || 'Price is required']"
                            ></v-text-field>

                         </v-form>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog dark v-model="dialogDelete" max-width="600px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete Product Option ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  No product options for this event
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import 'izitoast/dist/css/iziToast.min.css'; // loading css 
  import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'
  
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,

      selectedStallHolder: '',

      headers: [
        { text: "Product Option Name", value: "ProductOptionName"},
        { text: "Price", value: "Price"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],

      productOptions: [],
      selectedAllProductOptions: [],

      stallHolders: [],
      stallHoldersList: [],

      editedIndex: -1,
      editedItem: {
        EventId: '',
        StallholderId: '',
        ProductOptionName: '',
        Price: ''
      },
      defaultItem: {
        EventId: '',
        StallholderId: '',
        ProductOptionName: '',
        Price: ''
      },
  
      valid: true
    }),
  
    computed: {
      ...mapState('filter', ['globalEventFilter',
      // 'allEvents',
      'allStallHolders']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Product Options' : 'Edit Product Options'
      },
      selectedEvent: {
        get() {
          return this.globalEventFilter
        },
        set (value) {
          console.log("EVENT IS CHANGED MAKE API REQUEST", value)
        }
      },
      // events() {
      //   return this.allEvents.map((obj) => {
      //     return {text: obj.EventName, value: obj.EventId}
      //   });
      // }
    },
  
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
  
    created() {
      this.halldeStallHolder();
      this.getAllProductOptions()
    },
    methods: {
      ...mapMutations('filter', ['setGlobalEventFilter',
      // 'setAllEvents',
      'setFilterStallHolders']),
      ...mapActions('admin', ['getProducts', 'createCategories']),
      getAllProductOptions() {

        let eventId = localStorage.getItem('setSelectedEventId')
        let StallholderId = localStorage.getItem('setSelectedStallholderId')

        let paramObj = {}
            paramObj.EventId = eventId;
            paramObj.StallholderId = StallholderId;

            console.log("paramObj")
            console.log(paramObj)

        this.getProducts(paramObj).then(response => {
          console.log("response")
          console.log(response)
          this.selectedAllProductOptions = response.ProductOptions;
          this.productOptions = response.ProductOptions;
        })
      },
      haddleChangeSH: function (obj) {

        this.productOptions = this.selectedAllProductOptions.filter(element => {
          return element.StallholderId === obj.StallholderId;
        });

        localStorage.setItem('setSelectedEventId', this.selectedEvent.EventId);
        localStorage.setItem('setSelectedStallholderId', obj.StallholderId);
        this.getAllProductOptions();
      },
      halldeStallHolder: function() {
        if (this.selectedEvent.EventName !== undefined) {
          this.haddleChangeEvent(this.selectedEvent);
        }
      },
      
      // haddleChangeEvent: function(obj) {
      haddleChangeEvent: function() {
          this.stallHolders = [];
          this.editedItem.StallholderId = '';
          // let selectedObj = this.allEvents.filter(element => {
          //   return element.EventId === obj.EventId;
          // });
          // this.stallHoldersList = selectedObj[0].Stallholders;
          // this.stallHolders = selectedObj[0].Stallholders.map((obj) => {
          this.stallHolders = this.globalEventFilter.Stallholders.map((obj) => {
            console.log("THIS HERE: ", this.globalEventFilter.Stallholders)
          return {text: obj.StallholderName, value: obj.StallholderId}
        });

      },

      editItem (item) {
        this.editedIndex = this.productOptions.indexOf(item);

        this.editedItem.EventId = item.EventId;
        this.editedItem.Price = item.Price;
        this.editedItem.ProductOptionId = item.ProductOptionId;
        this.editedItem.ProductOptionName = item.ProductOptionName;
        this.editedItem.StallholderId = item.StallholderId;
        

        let selected = this.allStallHolders.filter(element => {
          return element.StallholderId === item.StallholderId;
        });

        this.selectedStallHolder = selected[0];

        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.allStallHolders.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.allStallHolders.splice(this.editedIndex, 1)
        
        try {
          if (this.editedItem.StallholderId) {
              let event_item = [
                  {
                      Type: "product_options",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createCategories(submitEvent).then(response => {
                  console.log("response")
                  console.log(response)

                  iziToast.success({
                      title: 'Product Options',
                      message: 'Successfully delete record!',
                      position: 'topRight'
                  });

                  this.getAllProductOptions();

              }).catch(err => {
                  console.log("err")
                  console.log(err)

                  iziToast.warning({
                      title: 'Product Options',
                      message: 'Fail to delete record!',
                      position: 'topRight'
                  });
              })
          }
        } catch (e) {
          console.log(e)
        }

        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        if (this.$refs.form.validate()) {

          try {
            if (this.$refs.form.validate()) {
              console.log("editedItem======")
              console.log(this.editedItem)
                this.editedItem.EventId = this.selectedEvent.EventId;
                this.editedItem.StallholderId = this.selectedStallHolder.StallholderId;
                let event_item = [
                    {
                        Type: "product_options",
                        Action: this.editedIndex === -1 ? 'Create' : 'Edit',
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }
                console.log("submitEvent")
                console.log(submitEvent)
                this.createCategories(submitEvent).then(response => {
                    console.log("response")
                    console.log(response)

                    iziToast.success({
                        title: 'Product Options',
                        message: 'Successfully inserted record!',
                        position: 'topRight'
                    });

                    this.getAllProductOptions();

                }).catch(err => {
                    console.log("err")
                    console.log(err)

                    iziToast.warning({
                        title: 'Product Options',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log(e)
          }
          this.close()
        }
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>