<template>
    <v-container fluid>
     
      <v-tabs
        v-model="tab"
        dark
        class="pl-3 pr-3"
        @change="haddleTabClick"
      >
        <v-tabs-slider></v-tabs-slider>
        
        <v-tab href="#tab-0">
          Categories
        </v-tab>
  
        <v-tab href="#tab-1">
          Tags
        </v-tab>
  
        <v-tab href="#tab-2">
          Products
        </v-tab>
  
        <v-tab href="#tab-3">
          Products Options
        </v-tab>

        <v-tab href="#tab-4">
          Layouts
        </v-tab>
  
      </v-tabs>
  
      <v-tabs-items v-model="tab" dark>
        
        <v-tab-item
          :value="'tab-0'"
        >
          <v-card flat style="background-color: #272b30;">
            <categories/>
          </v-card>
        </v-tab-item>
  
        <v-tab-item
          :value="'tab-1'"
        >
          <v-card flat style="background-color: #272b30;">
            <event-tags/>
            <stallholder-tags/>
          </v-card>
        </v-tab-item>
  
        <v-tab-item
          :value="'tab-2'"
        >
          <v-card flat style="background-color: #272b30;">
            <products/>
          </v-card>
        </v-tab-item>
  
        <v-tab-item
          :value="'tab-3'"
        >
          <v-card flat style="background-color: #272b30;">
            <products-option/>
          </v-card>
        </v-tab-item>

        <v-tab-item
          :value="'tab-4'"
        >
          <v-card flat style="background-color: #272b30;">
            <layouts/>
          </v-card>
        </v-tab-item>
        
  
      </v-tabs-items>
    </v-container>
  </template>
  <script>
    import Products from '../ProductManagement/Products/products.vue'
    import productsOption from '../ProductManagement/ProductOptions/productOptions.vue'
    import Categories from '../ProductManagement/Categories/categories.vue'
    import stallholderTags from '../ProductManagement/Tags/stallholderTags.vue'
    import eventTags from '../ProductManagement/Tags/eventTags.vue'
    import layouts from '../ProductManagement/Layouts/Layouts.vue'
    export default {
      components: {
        Products,
        productsOption,
        Categories,
        stallholderTags,
        eventTags,
        layouts
      },
      data () {
        return {
          tab: null,
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
      },
      created() {
        this.checkSelectedTab();
      },
      methods: {
        haddleTabClick: function(val) {
          localStorage.setItem('setSelectedTab', JSON.stringify(val))
    
        },
        checkSelectedTab: function() {
          let selectedName = JSON.parse(localStorage.getItem('setSelectedTab'));
          if (selectedName && selectedName !== null) {
            this.tab = selectedName;
          }
        }
      }
    }
  //   export default {
  //     name: 'Home'
  //   }
  
  </script>
  
  <style scoped>
  
  body {
      background-color: #272b30;
  }
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  p{
    color: #6e6e6e;
    font-family: sans-serif;
  }
  </style>